<template>
    <div class="suggest">
        <header class="suggest-header">
            <h1>医共体信息化建议规划与设计</h1>
        </header>
        <div class="suggest-content">
            <div class="suggest-item" v-if="data.one != void 0">
                <module-title :title="data.one.title" />
                <p class="text">{{ data.one.text }}</p>
            </div>
            <div class="suggest-item two" v-if="data.two != void 0">
                <module-title :title="data.two.title" />
                <p class="text">{{ data.two.text }}</p>
                <ul class="suggest-list">
                    <li v-for="(item, idx) in data.two.list" :key="idx">
                        <img :src="item.img" alt="">
                        <div>{{ item.text }}</div>
                    </li>
                </ul>
            </div>
            <div class="suggest-item three" v-if="data.three != void 0">
                <module-title :title="data.three.title" />
                <ul class="suggest-list">
                    <li v-for="(item, idx) in data.three.list" :key="idx">
                        <img :src="item.img" alt="">
                        <h3>{{ item.title }}</h3>
                        <p>{{ item.desc }}</p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    // 医院信息平台项目管理服务
    // 组件
    import ModuleTitle from '@/components/FhiryModuleTitle';

    // vuex
    import { mapMutations } from 'vuex';

    // API
    import { getSuggest } from '@/api/request';

    export default {
        name: 'manager',
        created () {
            this.getSuggestAjax();
        },
        data () {
            return {
                data: ''
            }
        },
        methods: {
            ...mapMutations([
                'setBusinessBanners'
            ]),
            getSuggestAjax () {
                getSuggest().then(res => {
                    this.setBusinessBanners(res.banners);
                    this.data = res.data;
                })
            }
        },
        components: {
            ModuleTitle
        }
    }
</script>

<style lang="less" scoped>
    .suggest {
        font-size: 14px;
        .suggest-header {
            padding: 20px 40px;
            border-bottom: 1px solid #ccc;
            h1 {
                font-size: 22px;
                font-weight: bold;
            }
        }
        .suggest-content {
            padding: 0 40px;
            .suggest-item {
                margin-top: 50px;
                line-height: 1.5;
                .text {
                    margin-top: 25px;
                }
                .suggest-list {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin-top: 30px;
                    li {
                        flex: 1;
                        margin-right: 20px;
                        text-align: center;
                        h3 {
                            margin-bottom: 10px;
                            color: #ea2539;
                            font-weight: bold;
                        }
                        &:last-child {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1023px) {
        .suggest {
            font-size: 14px;
            .suggest-content {
                padding: 0 40px;
                .suggest-item {
                    margin-top: 50px;
                    line-height: 1.5;
                    .text {
                        margin-top: 25px;
                    }
                    .suggest-list {
                        justify-content: flex-start;
                        flex-wrap: wrap;
                        li {
                            flex: none;
                            margin-right: 20px;
                            text-align: center;
                        }
                    }
                    &.two {
                        .suggest-list {
                            li {
                                margin-bottom: 20px;
                                width: calc(~'(100% - 40px) / 3');
                                &:nth-child(3n) {
                                    margin: 0;
                                }
                                &:last-child {
                                    margin-right: 20px;
                                }
                            }
                        }
                    }
                    &.three {
                        .suggest-list {
                            li {
                                margin-right: 0;
                                margin-bottom: 20px;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
</style>